import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const headerImgCarouselContainer =
    document.querySelector('.headerImgCarousel');
  const pageHeaderSlides = document.querySelectorAll('.pageHeaderSlide');

  if (headerImgCarouselContainer) {
    if (0 < pageHeaderSlides.length) {
      new Swiper(headerImgCarouselContainer, {
        slidesPerView: 1,
        autoplay: {
          delay: 3000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        },
        loop: true,
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
        mousewheel: {
          enabled: true,
          forceToAxis: true,
        },
        a11y: {
          prevSlideMessage: _x('Previous slide', 'Swiper'),
          nextSlideMessage: _x('Next slide', 'swiper'),
        },
        navigation: {
          nextEl: '.pageHeaderCarouselNext',
          prevEl: '.pageHeaderCarouselPrev',
        },
        pagination: {
          el: '.pageHeaderCarouselPagination',
          type: 'fraction',
        },
      });
    }
  }
});
